import PropTypes from 'prop-types';
import * as React from 'react';

type Properties = {
    children?: React.ReactChild | React.ReactChild[];
};

const SectionText = ({ children }: Properties): React.ReactElement => {
    return <div className={'max-w-4xl mx-auto text-center'}>{children}</div>;
};

SectionText.propTypes = {
    children: PropTypes.node,
};

SectionText.defaultProps = {};

export { SectionText };
