import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';

import { Container } from './container';

enum Style {
    Default = 'default',
    Light = 'light',
    Odd = 'odd',
    Gradient = 'gradient',
    Callout = 'callout',
}

type Properties = {
    id?: string;
    children?: React.ReactChild | React.ReactChild[];
    style?: Style;
    className?: string;
};

const Section = ({ id, children, style, className }: Properties): React.ReactElement => {
    const renderStyle = style || Section.defaultProps.style;

    const styles = new Map<string, string>([
        [Style.Default, ''],
        [Style.Light, 'bg-caribbean-50'],
        [Style.Odd, 'bg-algae-50'],
        [Style.Gradient, 'bg-gradient-to-r from-algae-100 to-lemon-100'],
        [Style.Callout, 'bg-lemon'],
    ]);

    const padding = new Map<string, string>([
        [Style.Default, 'py-section'],
        [Style.Light, 'py-section'],
        [Style.Odd, 'py-section'],
        [Style.Gradient, 'py-section-lg'],
        [Style.Callout, 'py-section-lg'],
    ]);

    return (
        <section id={id} className={classNames(styles.get(renderStyle), className)}>
            <Container className={classNames(padding.get(renderStyle))}>{children}</Container>
        </section>
    );
};

Section.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node,
    style: PropTypes.oneOf([Style.Default, Style.Light, Style.Odd, Style.Gradient, Style.Callout]),
    className: PropTypes.string,
};

Section.defaultProps = {
    style: Style.Default,
    className: '',
};

export { Section, Style };
