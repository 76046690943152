import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';

import { Button, Style, Type } from './button';

type Properties = {
    style?: Style;
    type?: Type;
    url?: string;

    caption: string;
    text?: string;
    callout?: boolean;
    forceBlank?: boolean;

    className?: string;
};

const TextButton = ({ style, type, url, caption, text, callout, forceBlank, className }: Properties): React.ReactElement => {
    const renderStyle = style || TextButton.defaultProps.style;
    const renderType = type || TextButton.defaultProps.type;

    const renderCallout = undefined === callout ? TextButton.defaultProps.callout : callout;

    return (
        <Button style={renderStyle} type={renderType} url={url} forceBlank={forceBlank} className={className}>
            <>
                <span className={classNames('block font-bold', { uppercase: renderCallout })}>{caption}</span>
                {text && <span className={'text-sm'}>{text}</span>}
            </>
        </Button>
    );
};

TextButton.propTypes = {
    style: PropTypes.oneOf([Style.Primary, Style.Secondary]),
    type: PropTypes.oneOf([Type.Button, Type.Link, Type.Submit]),
    url: PropTypes.string,

    caption: PropTypes.string.isRequired,
    text: PropTypes.string,
    callout: PropTypes.bool,
    forceBlank: PropTypes.bool,

    className: PropTypes.string,
};

TextButton.defaultProps = {
    style: Style.Primary,
    type: Type.Button,

    text: '',
    callout: true,

    className: '',
};

export { TextButton, Style, Type };
