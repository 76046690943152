import * as React from 'react';

import { Section, Style as SectionStyle } from '../components/containers/section';
import { SectionText } from '../components/containers/section-text';
import { TextButton, Type as ButtonType } from '../components/elements/text-button';
import { Header } from '../components/layout/header';
import { Site } from '../components/shells/site';
import { Heading, Level as HeadingLevel, Style as HeadingStyle } from '../components/typography/heading';
import { PageTitle } from '../components/typography/page-title';
import { Seo } from '../components/utilities/seo';

export default (): React.ReactElement => {
    return (
        <Site>
            <Seo title="Pagina niet gevonden" />

            <Header />

            <Section style={SectionStyle.Light}>
                <SectionText>
                    <PageTitle>Pagina niet gevonden</PageTitle>
                    <p>
                        De link die je hebt gebruikt klopt niet (meer). Heb je deze ergens op mijn website gevonden?{' '}
                        <a href={'mailto:vicky@fitfoodforty.nl'} className={'underline'}>
                            Mail me dan even
                        </a>
                        , als je wilt.
                    </p>
                </SectionText>
            </Section>

            <Section>
                <div className={'grid sm:grid-cols-2 md:grid-cols-3 gap-12'}>
                    <div>
                        <Heading level={HeadingLevel.Text} style={HeadingStyle.Secondary}>
                            Fitfoodforty
                        </Heading>
                        <p>Fitfoodforty geeft persoonlijke leefstijlcoaching om je leefstijl blijvend te veranderen.</p>
                        <TextButton type={ButtonType.Link} url={'/'} caption={'Homepage'} className={'mt-6'} />
                    </div>
                    <div>
                        <Heading level={HeadingLevel.Text} style={HeadingStyle.Secondary}>
                            Powerwalking
                        </Heading>
                        <p>Ik geef groepslessen powerwalking in regio Maastricht. Zet de eerste stap!</p>
                        <TextButton type={ButtonType.Link} url={'/powerwalking'} caption={'Powerwalking'} className={'mt-6'} />
                    </div>
                    <div>
                        <Heading level={HeadingLevel.Text} style={HeadingStyle.Secondary}>
                            Contact
                        </Heading>
                        <p>Vragen? Of wil je me laten weten waar je deze defecte link hebt gevonden?</p>
                        <TextButton type={ButtonType.Link} url={'/contact'} caption={'Neem contact op'} className={'mt-6'} />
                    </div>
                </div>
            </Section>
        </Site>
    );
};
